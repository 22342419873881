/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
body { background: #fff !important; font-family: Arial, Helvetica, sans-serif !important; }
.btn-primary {
   border-radius: 0px;
}
.form-control { border-radius: 0px; }
.form-group label, .form-group p { font-weight: 700; }
.colorPkrClose {
	display: none;
}
.colorPkrShow {
	display: block;
}
.timezone-color {
	color: #28C76F;

}
.project-view-color {
	color: #407EC9;
}
.font-size-25 {
	font-size: 1.25rem ! important;
}
.close {
	top: 2px;
    right: 10px;
}
.upload-button {
	background-color: #702F8A ! important;
}
.navbar-mb {
	margin-bottom: 10px;
}
.navtoggle-mt {
	// margin-top: 9px;
}

// InValid Select
.invalid {
	.select__control {
	  border-color: #ea5455;;
	}
  }

.show-error-msg {
	color: #ea5455;
	display: block;
}
.hide-error-msg {
	display: none;
}
.show-message {
	color: #ea5455;
	display: block;
}
.hide-message {
	display: none;
}
.show-list {
	display: block;
}
.hide-list {
	display: none;
}
.show-add-item {
	display: block;
}
.hide-add-item {
	display: none;
}
.justify-content-center {
	justify-content: center !important;
}

// .react-select {z-index: 9;}
.dataTable-filter-select {z-index: 9;}

.invalid_members, 
.invalid_presenters, 
.invalid_tele, 
.invalid_pre_stage, 
.invalid_post_stage, 
.invalid_stage {
	.select__control {
	  border-color: #ea5455;;
	}
  }
.dataTable-filter-select {
	width: 150px;
}
.setStatus {
	width: 175px;
}
.rdt_TableBody:hover{ cursor: all-scroll; }
.react-dataTable, .rdt_TableBody{ -webkit-overflow-scrolling: touch; }
.react-dataTable::-webkit-scrollbar{ -webkit-appearance: none; height: 10px; }
.rdt_TableBody::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 10px;
}
.rdt_TableBody::-webkit-scrollbar-track, .react-dataTable::-webkit-scrollbar-track {
	background: #f1f1f1;
}
.rdt_TableBody::-webkit-scrollbar-thumb, .react-dataTable::-webkit-scrollbar-thumb {
	background: #888;
}
.rdt_TableBody::-webkit-scrollbar-thumb:hover, .react-dataTable::-webkit-scrollbar-thumb:hover {
	background: #555;
}
.customCheck .custom-control-label::before, .customCheck .custom-control-label::after{ width: 36px; height: 36px; }

// .auth-bg {background-color: #fff;}
.cardtext-label {color: #5e5873; font-size: 0.857rem;}
.custom-control-Primary {color: #7468f0;}
.table-match {
	.hr-bottom { border-top: 1px solid #EBE9F1;}
	form .col-12:nth-last-child(2) hr{
		display: none;
	}
}

.leadonboard-text-black {
	color: #000;
}

/* src/styles.css */
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --font-color: 230 35% 7%;
}
body {
  color: hsl(var(--font-color));
}
img {
  max-width: 100%;
  display: block;
}
.section {
  padding: 2em 0;
  display: flex;
  min-height: 100vh;
  align-items: center;
}
.container {
  margin-inline: auto;
  max-width: 75rem;
  width: 85%;
}
.input__group {
  display: flex;
  margin-top: 2em;
}
.qrcode-input {
  width: 100%;
  padding: 1em 0.75em;
  border: 1px solid #444343;
  border-radius: 3px;
  margin-bottom: 2em;
  margin-top: 0.75em;
}
button {
  border: unset;
  background: hsl(231, 77%, 90%);
  padding: 1em 0.75em;
  color: hsl(var(--font-color));
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
}
.qrcode__container {
	padding: 100px;
  }
@media screen and (min-width: 768px) {
  .section {
    padding: 0;
  }
  .qrcode-input {
    margin: 0;
  }
  .qrcode__container {
    display: flex;
    align-items: center;
  }
  .input__group {
    margin-left: 3em;
  }
  .qrcode-input {
    margin-bottom: 2em;
    margin-top: 0.75em;
    font-size: 1rem;
  }
}

.line-height {
  line-height: 0.8rem;
}

.input-group-text-error {
  border-color: #ea5455;
}